import React, {useContext, useEffect, useRef, useState} from 'react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import folderIcon from './icons/folder.png';
import prevIcon from './icons/prev.png';
import './File.css';
import File from "./File";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Checkbox, IconButton, Skeleton, Tooltip} from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import {Check, Download as DownloadIcon, Favorite, Share} from "@mui/icons-material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import * as streamSaver from 'streamsaver';
import * as ZIP from './Zip'
import {Captions} from "yet-another-react-lightbox/plugins";
import DataContext from "../../globalstates/DataContext";


export default function FileBrowser({basePath, setNavigation, setTopCurrentPath}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(new Map());
    const [error, setError] = useState(false);
    const [openSlider, setOpenSlider] = useState(false);
    const [index, setIndex] = useState(0);

    const [currentPath, setCurrentPath] = useState(basePath);
    const [likes, setLikes] = useState(new Map());

    const [copySuccess, setCopySuccess] = useState(false);
    const [shareItem, setShareItem] = useState(undefined);
    const [downloadingItems, setDownloadingItems] = useState({});
    const {pathLocked, logedIn} = useContext(DataContext);

    const startDownloadingCounter = (id) => {
        setDownloadingItems((prevState) => ({
            ...prevState,
            [id]: { total: 1, done: 0 },
        }));
    };

    const addTotalDownloadingCounter = (id, total) => {
        setDownloadingItems((prevState) => {
            const current = prevState[id] || { total: 0, done: 0 };
            return {
                ...prevState,
                [id]: { ...current, total: current.total + total },
            };
        });
    };

    const endDownloadingCounter = (id) => {
        setDownloadingItems((prevState) => {
            const newState = { ...prevState };
            delete newState[id];
            return newState;
        });
    };

    const incrementDownloadingCounter = (id) => {
        setDownloadingItems((prevState) => {
            const current = prevState[id] || { total: 0, done: 0 };
            return {
                ...prevState,
                [id]: { ...current, done: current.done + 1 },
            };
        });
    };

    const handleCloseCopySuccess = () => {
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 5000);
    }

    const updateData = (k, v) => {
        if (data.get(k)) {
            data.set(k, v);
            setData(data);
        }
    };

    const updateLike = (k, v) => {
        likes.set(k, v);
        setLikes(likes);
    };

    const setFileLike = async (filePath, like) => {
        try {
            const response = await fetch('https://setfilelike-vgjwhs6wxq-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ path: filePath, like: like })
            });
            if (!response.ok) {
                throw new Error('Failed to set file like');
            }
            setLikes(likes.set(filePath, like));
        } catch (error) {
        }
    };

    const loadLikes = async () => {
        try {
            const response = await fetch('https://loadlikes-vgjwhs6wxq-uc.a.run.app');
            if (!response.ok) {
                throw new Error('Failed to load likes');
            }
            const result = await response.json();
            const likesMap = new Map();
            Object.keys(result).forEach(function(key) {
                likesMap.set(key, result[key]);
            });
            setLikes(likesMap);

        } catch (error) {
            console.error('Error loading likes:', error);
        }
    };


    useEffect(() => {
        if (basePath === undefined) {
            return;
        }
        const urlPath = decodeURIComponent(window.location.hash.substring(1)).replace(/;/g, '/');
        if (urlPath) {
            const initialPath = basePath + '/' + urlPath;
            setCurrentPath(initialPath);
        }
    }, [basePath]);

    useEffect( () => {
        setData(new Map());
        setLoading(true)
        let share = undefined;
        let shareLowerPath = undefined;
        share = atob((new URL(window.location)).searchParams.get('share'))?.split(':');
        if (share[0]) {
            shareLowerPath = share[0];
            share = share[0].split('/').slice(0, -1).join('/');
            if (share && share.length > 0) {
                setCurrentPath(share);
            }
        }

        if (basePath === undefined) {
            return;
        }

        let path = currentPath || basePath;

        // no store on upper level, so this dummy solution, im sorry
        if (path === undefined) {
            return;
        }

        setLoading(true);
        //fetch(`http://127.0.0.1:5001/comfort-films/us-central1/getDBoxContentTree?path=${path}`)
        fetch(`https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${path}`)
            .then(response => response.json())
            .then(newData => {
                newData.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });

                setData(new Map(newData.map(entry => [entry.name, entry])));
                setLoading(false);
                if (shareLowerPath) {
                    const shareItem = newData.find(entry => entry.path_lower === shareLowerPath);
                    if (shareItem) {
                        setShareItem(shareItem);
                    }
                }
                newData.forEach(async entry => {
                    if (entry['.tag'] === 'file') {
                        entry.fullUrl = (await loadFullUrl(entry.path_lower));
                       // updateData(entry.name, entry);
                    }
                });

                return newData;

            })
            .then((newData) => {
                const url = new URL(window.location);
                if (url.searchParams.has('file')) {
                    const fileName = decodeURIComponent(url.searchParams.get('file'));
                    const shareItem = newData.find(entry => entry.name.includes(fileName));
                    if (shareItem) {
                        setShareItem(shareItem);
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(true);
            });

         loadLikes();
    }, [currentPath, basePath]);


    useEffect(() => {
        if (!basePath || !currentPath) {
            return;
        }
        const copy = currentPath.toLowerCase();
        const items = copy.replace(basePath.toLowerCase(), '').split('/').filter(i => i.length > 0);

        const newPath = items.join(';');

        window.history.pushState(null, '', `#${newPath}`);
        setTopCurrentPath(currentPath);

        setNavigation([
            items.map(
                (item, index) => {
                    const path = basePath + '/' + items.slice(0, index + 1).join('/');
                    const actionBlocked = pathLocked && path.length < pathLocked.length;

                    return <span> / <span key={item} className={`navigation-item ${actionBlocked ? 'non-clickable' : ''}`} onClick={() => {
                        const path = basePath + '/' + items.slice(0, index + 1).join('/');
                        if (actionBlocked) {
                            return;
                        }
                        setCurrentPath(path);
                    }}> {item.toUpperCase()}</span></span>;
                }
            )
        ]);
    }, [currentPath]);


    const handleFolderClick = (path) => {
        setCurrentPath(path);
    };

    const goBack = () => {
        if (basePath.length === currentPath.length) {
            return;
        }
        if (currentPath === pathLocked) {
            return;
        }

        setCurrentPath(currentPath.split('/').slice(0, -1).join('/'));
    }

    const canGoBack = pathLocked ? (pathLocked && currentPath && currentPath.trim().normalize().toLowerCase() !== pathLocked.trim().normalize().toLowerCase()) : currentPath !== basePath;




    const loadFullUrl = async (path) => {
        try {
            const response = await fetch(`https://loadfullurl-vgjwhs6wxq-uc.a.run.app?path=${encodeURIComponent(path)}`);
            if (!response.ok) {
                throw new Error('Failed to load full URL');
            }

            const data = await response.json();
            return data.link;
        } catch (error) {
            console.error('Error loading full URL:', error);
            throw error;
        }
    };

    const assetLink = (asset, width) => {
        return asset;
    }

    const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];

    const slides = Array.from(data?.values()).filter(entry => entry['.tag'] === 'file' && entry.name.toLowerCase().match(/\.(jpeg|jpg|png|gif)$/)).map(entry => ({
        id: entry.name,
        title: entry.name,
        asset: entry.fullUrl,
        width: 3840, height: 2560
    }))
        .map(({asset, width, height, id, title}) => ({
            id: id,
            src: assetLink(asset, width),
            width,
            height,
            srcSet: breakpoints.map((breakpoint) => ({
                src: assetLink(asset, breakpoint),
                width: breakpoint,
                height: Math.round((height / width) * breakpoint),
            })),
            title: title,
            download: {
                filename: title
            },
            downloadFilename: title
        }));

    const videoSlides = Array.from(data?.values()).filter(entry => entry['.tag'] === 'file' && entry.name.toLowerCase().match(/\.(mp4|mov|avi)$/))
        .map(entry => ({
            id: entry.name,
            src: entry.fullUrl,
            type: "video",
            width: 1280,
            height: 720,
            poster: `data:image/*;base64,${entry.thumbnail}`,
            sources: [
                {
                    src:
                    entry.fullUrl,
                    type: "video/mp4"
                }
            ],
            title: entry.name,
            download: {
                filename: entry.name
            },
            downloadFilename: entry.name,
            downloadUrl: entry.fullUrl
        }));
    slides.push(...videoSlides);

    const pdfSlides = Array.from(data?.values()).filter(entry => entry['.tag'] === 'file' && entry.name.toLowerCase().match(/\.(pdf)$/))
        .map(entry => ({
            id: entry.name,
            src: entry.fullUrl,
            type: "custom-pdf",
            width: 1280,
            height: 720,
            poster: `data:image/*;base64,${entry.thumbnail}`,
            title: entry.name,
            download: {
                filename: entry.name
            },
            downloadFilename: entry.name,
            downloadUrl: entry.fullUrl,
            entry: entry
        }));
    slides.push(...pdfSlides);

    if (shareItem) {
        loadFullUrl(shareItem.path_lower)
            .then(() => {
                shareItem.loading = false;
                shareItem.fullUrl = shareItem.fullUrl || '';
                updateData(shareItem.name, shareItem);
                setIndex(slides.findIndex(slide => slide.id === shareItem.name));
                setOpenSlider(true);
                setShareItem(null);
                const url = new URL(window.location);
                url.searchParams.delete("share");
                window.history.pushState('page2', 'Title', url.toString());
            })
    }

    const toggleLike = (fileName) => {
        const file = data.get(fileName);
        const currentLike = likes.get(file.path_lower);
        const newLike = !currentLike;

        // Update the likes state
        setLikes(new Map(likes.set(file.path_lower, newLike)));

        // Update the file data
        file.liked = newLike;
        updateData(fileName, file);

        // Call the API to set the like
        setFileLike(file.path_lower, newLike);
    };

    if (error) {
        return <div className="loader-container" style={{color: "white", fontSize: 24}}>Sorry! Something went wrong... Please try again later</div>;
    }

    if (loading) {
        return <div className='file-browser'>
            <div className="file-browser-grid">
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
                <Skeleton variant="rounded" width={256} height={256}/>
            </div>
        </div>;
    }


    /**
     * DOWNLOAD FOLDER FUNCTION
     * @param path
     * @param saveName
     * @param loadingId
     * @returns {Promise<void>}
     */
    const downloadPath = async (path, saveName, loadingId) => {
        try {
            startDownloadingCounter(loadingId);

            const fileStream = streamSaver.createWriteStream(`${saveName}.zip`);
            const fileQueue = []; // Queue to hold files to be processed
            const BATCH_SIZE = 20; // Number of files to download concurrently
            const response = await fetch(
                `https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${path}`
            );
            if (!response.ok) throw new Error(`Failed to fetch items: ${response.statusText}`);
            const items = await response.json();

            // Add root items to the queue with their relative paths
            items.forEach((item) => fileQueue.push({ ...item, relativePath: item.name }));

            const readableZipStream = new ZIP.default({
                async start(ctrl) {
                },
                async pull(ctrl) {
                    if (fileQueue.length === 0) {
                        ctrl.close(); // No more files to process
                        return;
                    }

                    // Process items in batches
                    const batch = fileQueue.splice(0, BATCH_SIZE);
                    const batchPromises = batch.map(async (item) => {
                        if (item[".tag"] === "folder") {
                            ctrl.enqueue({ name: item.relativePath, directory: true });

                            const folderContents = await (
                                await fetch(
                                    `https://getdboxcontenttree-vgjwhs6wxq-uc.a.run.app?path=${item.path_lower}`
                                )
                            ).json();
                            addTotalDownloadingCounter(loadingId, folderContents.length);

                            folderContents.forEach((subItem) => {
                                fileQueue.push({
                                    ...subItem,
                                    relativePath: `${item.relativePath}/${subItem.name}`,
                                });
                            });

                        } else {
                            const response = await fetch(item.fullUrl || (await loadFullUrl(item.path_lower)));
                            if (response.ok) {
                                ctrl.enqueue({
                                    name: item.relativePath, // Use relativePath to maintain hierarchy
                                    stream: () => response.body,
                                });
                                incrementDownloadingCounter(loadingId);

                            } else {
                            }
                        }
                    });

                    await Promise.all(batchPromises);
                },
            });

            if (window.WritableStream && readableZipStream.pipeTo) {
                await readableZipStream.pipeTo(fileStream);
            } else {
                const writer = fileStream.getWriter();
                const reader = readableZipStream.getReader();

                const pump = async () => {
                    const { done, value } = await reader.read();
                    if (done) {
                        writer.close();
                    } else {

                        await writer.write(value);
                        await pump();
                    }
                };
                await pump();
            }

        } catch (error) {
            console.error(error);
            alert(`We are sorry! Folder size too large to download.`);
        } finally {
            endDownloadingCounter(loadingId);
        }
    };


    return (
        <div>
            <div className="file-browser">
                {canGoBack && (
                    <div className="file" onClick={() => goBack()}
                         style={{cursor: 'pointer', display: 'flex', alignItems: 'center', width: 220, marginLeft: 4, marginBottom: 10}}>
                        <img className="file-icon" src={prevIcon} alt={`back icon`}/>
                        <span className="file-name">Back</span>
                    </div>
                )}
                <div className="file-browser-grid">
                    {Array.from(data?.values()).map(item => (
                        item['.tag'] === 'file' ? (
                            <File key={item.name} type={item['.tag']} name={item.name} icon={null} path={item.path_lower} previewBase64={item.thumbnail} loading={(!item.fullUrl && item.loading) || false}
                                  onClick={
                                      () => {
                                          const url = new URL(window.location);
                                          url.searchParams.set("file", item.name);
                                          window.history.pushState({}, '', url);

                                          if (item.name.toLowerCase().match(/\.(jpeg|jpg|png|gif|mp4|mov|avi|pdf)$/)) {
                                              if (!item.fullUrl || item.fullUrl === '') {
                                                  item.loading = true;
                                                  loadFullUrl(item.path_lower)
                                                      .then(() => {
                                                            item.loading = false;
                                                            item.fullUrl = item.fullUrl || '';
                                                            updateData(item.name, item);
                                                      })
                                              }
                                              setIndex(slides.findIndex(slide => slide.id === item.name));
                                              setOpenSlider(true);
                                          } else {
                                              window.open(item.fullUrl, '_blank');
                                          }
                                      }
                                  }
                                  onLike={() => toggleLike(item.name)}
                                  isLiked={likes.get(item.path_lower) || false}
                            >

                            </File>
                        ) : (
                            <div key={item.id} className="file" onClick={() => handleFolderClick(item.path_lower)}
                                 style={{
                                     cursor: 'pointer',
                                     display: 'flex',
                                     alignItems: 'center',
                                     position: 'relative'
                                 }}>
                                <img className="file-icon" src={folderIcon} alt={`${item['.tag']} icon`}/>
                                <span className="file-name">{item.name}</span>
                                <IconButton style={{
                                    position: 'absolute',
                                    right: '10px',
                                    bottom: '10px',
                                }}
                                            onClick={async (e) => {
                                                e.stopPropagation(); // Prevent event propagation
                                                if (window.confirm('Do you want to download the folder?')) {
                                                    downloadPath(item.path_lower, item.name, item.id);
                                                }
                                            }}>
                                    {downloadingItems[item.id] && downloadingItems[item.id].hasOwnProperty('total') ? <CircularProgressWithLabel value={Math.round((downloadingItems[item.id].done/downloadingItems[item.id].total) * 100)} /> :
                                        <DownloadIcon fontSize={"large"} color={"secondary"}/>}
                                </IconButton>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <Lightbox
                open={openSlider}
                index={index}
                close={() => {
                    setOpenSlider(false);
                    const url = new URL(window.location);
                    url.searchParams.delete("file");
                    window.history.pushState({}, '', url);
                }}
                plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom, Download, Captions]}
                slides={slides}
                on={{
                    view: (index) => {
                        setIndex(index.index);
                    }
                }}
                render={{
                    slide: ({slide}) =>
                        slide && slide.type === 'custom-pdf' ? (
                            <PdfSlide slide={slide}/>
                        ) : undefined,
                    thumbnail: ({slide, posterIndex}) => {
                        return (
                            slide && slide.type === 'custom-pdf' ? <img
                                src={slide.poster || slide.src}
                                alt={slide.title}
                                style={{
                                    width: 100,
                                    height: 100,
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    borderRadius: 8,
                                    border: posterIndex === undefined || slides[posterIndex] === undefined || slides[posterIndex].id !== slide.id ? 'none' : '2px solid #ff0000'
                                }}
                            /> : undefined
                        );
                    }

                }}
                toolbar={{
                    buttons: [
                        <Checkbox
                            icon={<FavoriteBorder color={"secondary"}/>}
                            size={"large"}
                            color={"secondary"}
                            checkedIcon={<Favorite/>}
                            checked={index !== undefined && slides[index] && likes.get(data.get(slides[index].id)?.path_lower) || false}
                            onChange={index !== undefined && slides[index] ? () => toggleLike(slides[index].id) : null}
                        />,
                        <Tooltip title="Copy link">
                            <IconButton color="primary" aria-label="Copy link"
                                        onClick={() => {
                                            handleCloseCopySuccess();
                                            const url = new URL(window.location);
                                            url.searchParams.set("share", btoa(data.get(slides[index].id)?.path_lower + ":" + logedIn.login + ":" + logedIn.password));
                                            navigator.clipboard.writeText(url.toString());
                                        }}
                            >
                                {copySuccess ? <Check fontSize={"large"}></Check> : <Share fontSize={"large"}/>}
                            </IconButton>
                            {copySuccess &&
                                <div style={{color: "white", position: "absolute"}} className="copy-to-clipboard">Copied
                                    to clipboard!</div>}
                        </Tooltip>,
                        "close"
                    ]
                }}
            />
        </div>
    );


    function PdfSlide({ slide }) {
        const [loading, setLoading] = useState(true);

        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {loading && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }}>
                        Loading PDF...
                    </div>
                )}
                <iframe
                    style={{ marginTop: '80px' }}
                    width="100%"
                    height="100%"
                    title={slide.title || 'PDF Viewer'}
                    src={'https://comfortfilms.online-stage.cz/file_loader.php?url=' + encodeURIComponent(slide.src)}
                    onLoad={() => setLoading(false)}
                />
            </div>
        );
    }
}