import React, {useContext, useEffect, useState} from "react"
import { useNavigate, useParams } from "react-router-dom";

// ICONS
import closeIcon from "../pictures/icons/close.svg";

import { getHeight } from "../functions/HelpingFunctions";
import FileBrowser from "./FileBrowser/Index";
import {Check, Share} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";
import useLocalStorageState from "use-local-storage-state";
import {defaultLogedIn} from "../App";
import DataContext from "../globalstates/DataContext";

export default function FileTableNew({ projectName, thisData }) {

    const navigate = useNavigate();
    const params = useParams();

    // SCALING
    const [scale, setScale] = useState(1);
    const [navigation, setNavigation] = useState([]);
    const [key, setKey] = useState(0);
    const [copySuccess, setCopySuccess] = useState(false);
    const [currentPath, setCurrentPath] = useState(false);
    const { pathLocked, logedIn } = useContext(DataContext);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale()
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])

    // Function to reset FileBrowser state
    function resetFileBrowser() {
        setKey(prevKey => prevKey + 1);
        if (!pathLocked) {
            const url = new URL(window.location);
            url.hash = '';
            window.history.pushState({}, '', url);
        }
    }


    function handleCloseCopySuccess() {
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 3000);
    }

    return(
        <div
        //onClick={() => navigate("/client/" + params.id + "/" + params.location )}
        style={{
            background: "rgba(0,0,0,0.8)",
            width: "100%",
            position: "fixed",
            zIndex: "100",
        }}>
            <div className="flex-col align-center">

                <div className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>

                    <div
                        style={{
                            position: "absolute",
                            transform: "translate(0px, -570px)",
                            left: "134px",
                            pointerEvents: "none"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#909090",
                                letterSpacing: "0.06em",
                                fontWeight: "600",
                                zIndex: "100"
                            }}
                        >{"* " + (projectName)?.toUpperCase()}</span>
                    </div>


                    <div
                        style={{
                            position: "absolute",
                            transform: "translate(0px, -573px)",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#909090",
                                letterSpacing: "0.06em",
                                fontWeight: "600",
                                //background: "#212121",
                                padding: "5px",
                                zIndex: "100"
                            }}
                        ><span className={`navigation-item ${pathLocked ? 'non-clickable' : ''}`} onClick={() => resetFileBrowser()}>{params.location.toUpperCase() + " / " + params.folder.toUpperCase()}</span>
                            {navigation}

                            <Tooltip title="Copy link">
                                <IconButton color="primary" aria-label="Copy link"
                                            onClick={() => {
                                                handleCloseCopySuccess();
                                                const url = new URL(window.location);
                                                url.searchParams.set("share", btoa('-' + ":" + logedIn.login + ":" + logedIn.password + ":" + (currentPath || thisData)));
                                                navigator.clipboard.writeText(url.toString());
                                            }}
                                >
                                    {copySuccess ? <Check fontSize={"medium"}></Check> : <Share fontSize={"medium"}/>}
                                </IconButton>
                            </Tooltip>

                        </span>
                    </div>



                    {!pathLocked && <button
                    onClick={() => navigate("/client/" + params.id + "/" +  params.location)}
                    className="filetable-close"
                    >
                        <img src={closeIcon} width="40" height="40"/>
                    </button>}

                    <div
                        className="file-scrollable"
                        style={{
                            width: "2120px",
                            height: "1200px",
                            border: "none",
                            borderRadius: "22px",
                            background: "#212121",
                        }}
                    >
                        <FileBrowser key={key} basePath={thisData} setNavigation={setNavigation} setTopCurrentPath={setCurrentPath}></FileBrowser>
                    </div>

                </div>
            </div>
        </div>

    )
}