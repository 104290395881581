import React, { useEffect, useState } from "react";

// LOCAL STORAGE LIBRARY
import useLocalStorageState from 'use-local-storage-state';

// ROUTER
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// GLOBAL STATES
import DataContext from './globalstates/DataContext';

// PAGES
import LandingPage from "./pages/LandingPage";
import ClientPage from "./pages/ClientPage";
import ProductionDesPage from "./pages/ProductionDesPage";
import WardrobePage from "./pages/WardrobePage";
import CastingPage from "./pages/CastingPage";

import FileTablePage from "./pages/FileTablePage";
import LocationPage from "./pages/LocationPage";

import { db } from "./config/firebase";
import { getDocs, collection } from "firebase/firestore";

import useGetData from "./hooks/useGetData";


// ROUTES
const router = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <LandingPage />,
  },

  {
    path: "/client/:id",
    exact: true,
    element: <ClientPage />,
  },



  {
    path: "/client/:id/:location",
    exact: true,
    element: <LocationPage />,
  },
  

  {
    path: "/client/:id/:location/:folder",
    exact: true,
    element: <FileTablePage />,
  },
  {
    path: "/client/:id/production-design",
    exact: true,
    element: <ProductionDesPage />,
  },

  {
    path: "/client/:id/wardrobe",
    exact: true,
    element: <WardrobePage />,
  },

  {
    path: "/client/:id/casting",
    exact: true,
    element: <CastingPage />,
  },
]);

export const defaultLogedIn = {
  login: null,
  password: null,
  loggedIn: false,
}


export default function App() {
    const url = new URL(window.location);
    const [logedInPersist, setLogedInPersist] = useLocalStorageState('logedInPersist', { defaultValue: defaultLogedIn });
    const [pathLocked, setPathLocked] = useState(null);
    const [tempLogin, setTempLogin] = useState(null);
    const [comeFrom, setComeFrom] = useLocalStorageState('comefrom', { defaultValue: "/" });
    const [thisData, setThisData] = useState(undefined);

    useEffect(() => {
      if (url.searchParams.has('share')) {
        const share = atob(url.searchParams.get('share')).split(':');
        setTempLogin({ login: share[1], password: share[2], loggedIn: true });
        setLogedInPersist(defaultLogedIn);
        if (share[3] && share[3].trim() !== pathLocked) {
          setPathLocked(share[3].trim());
        }
      }
    }, [url.searchParams.toString()]);

    const logedIn = tempLogin || logedInPersist;
    const check = useGetData(logedIn?.login, logedIn?.password);

    useEffect(() => {
      if (check) {
        setThisData(check);
      } else if (check === false) {
        setLogedInPersist(defaultLogedIn);
      }
    }, [check]);

    const setLoggedIn = (data) => {
      setLogedInPersist(data);
      setTempLogin(data);
    };

  return (
      <DataContext.Provider value={{ logedIn, setLoggedIn, comeFrom, setComeFrom, thisData, pathLocked }}>
        <RouterProvider router={router} />
      </DataContext.Provider>
  );
}

